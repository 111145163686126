/* src/app/app.component.scss */
.loader {
  z-index: 99999;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
}
ion-spinner {
  width: 128px;
  height: 128px;
}
/*# sourceMappingURL=app.component.css.map */
